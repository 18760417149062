    <template>
  <div>
    <div>
      <img src="../img/reg-bg.png" alt="" id="bg" />
    </div>
    <div id="mask_body" style="top: 44px">
      <img
        src="../img/logo-i.png"
        alt=""
        class="logo58 customization_elements"
      />
      <span class="logo">聚信人和</span>
    </div>
    <div class="password_login">
      <div class="account_login">
        <div class="mask_body_item" id="mask_body_item_user_name">
          <input
            type="tel"
            class="pop_input"
            id="mask_body_item_phonenum"
            placeholder="手机号"
            maxlength="11"
            v-model="shoujihao"
          />
        </div>
        <div class="mask_body_item">
          <input
            type="password"
            placeholder="密码"
            class="pop_input2"
            id="mask_body_item_newpassword"
            maxlength="16"
            v-model="password"
          />
        </div>
        <div class="protocol reg">
          <input type="checkbox" id="checkbox" v-model="message" />
          <label for="checkbox"
            >已阅并同意
            <router-link target="_blank" to="/yszc">
              《用户服务协议》</router-link
            >和<router-link target="_blank" to="/yhxy">
              《隐私协议》</router-link
            ></label
          ><br><br>
          <router-link to="/">返回首页</router-link>
        </div>
        <div class="mask_body_item ">
          <button
            loginmode="accountlogin"
            id="mask_body_item_login"
            class="btn"
            style="opacity: 0.8"
            @click.prevent="queding"
          >
            <a > 确定</a>
          </button>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Denglu",
  data() {
    return {
      shoujihao: "",
      message: false,
      password: "",
      code: "",
      //发送成功动态码
      dtm: false,
      //获取动态码
      dtm1: true,
    };
  },
  methods: {
    //获取动态码

    //确定按钮提交
    queding() {
      axios
        .post(
          // "http://192.168.28.37:15001/account",
          "http://123.56.43.64:15001/account",
          {
            phone: this.shoujihao,
            password: this.password,
            platnum: 100,
          }
        )
        .then((res) => {
          console.log("nihaozxchas", res);
          if (this.message != true) {
            alert("请阅读并勾选协议");
          }  else {
            this.$router.push("./Denglu");
          }
        });
    },
  },
};
</script>
<style scoped>
#bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
#mask_body,
#new_mask_body {
  position: absolute;
  top: 100px;
  background-color: #fff;
  margin-left: 50%;
  left: -238px;
  width: 476px;
  height: 400px;
  padding-top: 62px;
  box-shadow: 0 2px 6px 2px rgb(0 0 0 / 24%);
  border-radius: 2px;
}
.logo58 {
  height: 36px;
  display: block;
  margin: 0 auto 0 180px;
  margin-bottom: 26px;
  vertical-align: bottom;
}
.logo {
  display: block;
  margin-left: 220px;
  margin-top: -58px;
  color: rgba(177, 171, 171, 0.966);
  font-size: 20px;
  font-weight: 800;
}
.change_login {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
  padding: 0;
  height: 50px;
  margin-top: 10px;
}
.active {
  color: #ff552e;
  border-bottom: 1px solid #ff552e;
}
.account_item {
  margin-left: 85px;
}
.change_login span {
  width: 50%;
  text-align: center;
  float: left;
  cursor: pointer;
  font-size: 16px;
  line-height: 55px;
}
.mask_body_item {
  width: 350px;
  font-size: 14px;
  margin: 0 auto;
  position: relative;
  padding: 13px 0;
  clear: both;
  margin-top: 298px;
}
#mask_body_item_user_name {
  position: relative;
}
.mask_body_item input {
  width: 331px;
  padding-left: 15px;
  border: 2px solid #ebebeb;
  height: 36px;
  line-height: unset;
  line-height: 36px\9;
  color: #000;
  outline: none;
  font-size: 14px;
}
.pop_input {
  display: block;
  margin-top: -120px;
}
.pop_input1 {
  display: block;
  margin-top: -380px;
}
.pop_input2 {
  display: block;
  margin-top: -370px;
}
#mask_body_item_login {
  width: 100px;
  height: 40px;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: -605px;
  right: 120px;
}
.btn {
  background: #ff552e;
  display: block;
}
a {
  text-decoration: none;
}
.btn a {
  color: aliceblue;
}
.disabled {
  color: #999999;
}
#mask_body_item_getcode,
#new_mask_body_item_getcode {
  position: absolute;
  padding: 0 6px;
  height: 26px;
  border-radius: 3px;
  background: #eeeeee;
  color: #ff552e;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  top: 50%;
  margin-top: -112px;
  right: 8px;
  cursor: pointer;
}
.protocol {
  position: relative;
  font-size: 14px;
  text-align: center;
  top: -320px;
  left: 770.9px;
  bottom: 10px;
  color: #999;
}
.reg {
  width: 350px;
  /* margin: -500px auto 10px auto; */
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>
